body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
    font-family: "Poppins", sans-serif;
}

.home {
    width: 100%;
    height: 90vh;
    background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url(../images/cloud-bg.png) no-repeat center center/cover;
    font-family: "Poppins", sans-serif;
}

.home .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
    overflow-x: hidden;
}

.home .content {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin-left: 30px;
}
.home .content h1 {
    width: 100%;
    display: flex;
    font-weight: bold;
    font-size: 4rem;
    font-family: "Poppins", sans-serif;
}

.m__container{
    display: flex;
    justify-content: space-evenly;
}

.c_photo {
    padding-left: 0; /* Remove padding to prevent overflow */
    text-align: center; /* Center the image if needed */
}

.c_photo img {
    max-width: 100%; /* Ensure the image does not overflow its container */
    height: auto; /* Maintain aspect ratio */
}

.image {
    position: absolute;
    right: 0; /* Adjust the right alignment to fit within the container */
    top: 52rem;
    width: 250px;
    height: 250px;
    margin: -60px 0 0 0;
    filter: invert(100%);
    animation: spin 40s linear infinite;
}
.v_container {
    width: 50%;
    box-shadow: 10px 10px 15px lightblue;
    padding: 50px;
}

.v_container h2 {
    text-align: center;
    position: relative;
    font-weight: 800;
    font-size: 2.5rem;
    margin-bottom: 60px;
}
.v_container h5 {
    margin-bottom: 25px;
    font-size: 2rem;
}
.v_container h6 {
    position: relative;
    
    font-size: 1.5rem;
}

@media screen and (max-width: 940px) {
    .m__container{
        flex-direction: column;
    }
    .v_container{
        width: 100%;
        box-shadow: 10px 10px 15px  lightblue;
    }

    .c_photo img {
        height: 60vh;
    }

    .v_container {
        margin-top: 3rem;
    }

    .v_container h2 {
        font-size: 2rem;
    }

    .image {
        display: none; /* Hide the spinning image on smaller screens */
    }
}
