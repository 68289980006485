.footer {
    width: 100%;
    height: auto;
    position: relative;
    font-family: 'poppins', sans-serif;
}

.footer:before {
    content:'';
    position: absolute;
    background:linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)) ,url('../images/foot.jpg')no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;

}

.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer {
    display: flex;
    align-items: center;
}

.footer .top .logo-footer img {
    width: 3.5rem;
    height: auto;
    border-radius: 50%;
}

.footer h2 {
    font-size: 2.3rem;
    color: white;
    font-weight: 600;
    
}
.icon___i{
    font-size: 4rem;
    color: white;
    border-radius: 50%;
    background: linear-gradient(150deg, #369ff6,  #02034e); 
    

    padding: 10px 10px;
    margin-top: -40px;
}
.icon___i:hover{
    box-shadow: 0px 0px 25px 10px lightblue;

}

.footer .col-container {
    display: grid;
    grid-template-columns: 3fr 2fr 2fr 2fr;
    padding: 1rem ;
    width: 100%;
}

.footer h5 {
    margin-top: 1rem;
    /* text-transform: uppercase; */
    color: white;
    font-weight: 600;
}


.footer p {
    margin: .5rem 0;
    color:rgb(198, 190, 190);
    cursor: pointer;

}
.footer p:hover{
 color: white;
}
.footer .spoon{
    color: whitesmoke;
    font-size: 3rem;
}

.icon{
    margin-right: 20px;
    
}



@media screen and (max-width: 940px) {
    .footer {
        height: 100%;
    }

    .footer .col-container {
        grid-template-columns: 3fr ;
    }

    .footer form {
        grid-column: 1 / span 2;
    }
}

.bss__footer{
    color:transparent;
    margin-left:0;
    margin-bottom: 20px;
    padding-bottom: 0 ;
    background: linear-gradient(150deg, #2e8bd7,  #03059c); 
    height: 0.3rem; 
    align-items: center;
    width: 30%;
    border-radius: 20px;
}
