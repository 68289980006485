*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  font-family: 'poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul{
  list-style-type: none;
}
a{
  text-decoration: none;
  color:black;
  font-size: 18px;
}

p{
  font-size: 1.2rem;
  line-height: 1.4rem;

}
h1{
  font-size: 3.5rem;
}
Route{
  color: navy;
}
::-webkit-scrollbar{
  display: none;
}