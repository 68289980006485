.slider-container {
    width: 80%;
    height: auto;
    margin: auto;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 12px 12px 2px 1px rgba(3, 3, 126, 0.2);
  }
  
  .slide {
    text-align: center;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
  }
  
  .review-container {
    position: relative;
    padding: 10px;
  }
  
  .quote-icon {
    font-size: 20px;
    color: #555;
  }
  
  .review-text {
    font-size: 1.2em;
    margin: 10px 0;
    color: #333;
    font-style: italic;
  }
  
  .name {
    font-weight: bold;
    margin-top: 15px;
    color: #222;
  }
  
  .mname {
    color: #666;
    font-size: 0.9em;
  }
  